import '../css/Menu.css';
import React from 'react';
import tel from '../imgs/tel-icon-footer.png';
import whats from '../imgs/icon-whatsApp.png';
import insta from '../imgs/instagram-icon.png';
import face from '../imgs/facebook-icon.png';
import PropTypes from 'prop-types';
import logo from '../imgs/logo.png';
import menu from '../imgs/menu.png';
import close from '../imgs/close.png'
import services from '../imgs/services.png';
import home from '../imgs/home.png';
import location from '../imgs/location.png';

class Menu extends React.Component {
  render() {
    const { handleClick, hide } = this.props;
    return (
    <div className={hide ? "hide-menu" : "menu"}>
      <div className="menu-top">
        <div className="menu-close" onClick={handleClick}>
          <img src={close} className="icon-close" alt="close" />
          <span className="menu-close-text light">Fechar</span>
        </div>
        <img className="menu-icon-show" src={menu} alt=""/>
        <img className="menu-logo" src={logo} alt=""/>
      </div>
      <div className="menu-bottom">
        <nav className="menu-items">
          <a href="#header" className="nav-menu-item light">
            <img src={home} alt="tel" className=""/>
            <span>HOME</span>
          </a>
          <a href="#services" className="nav-menu-item light">
            <img src={services} alt="tel" className=""/>
            <span>SERVIÇOS</span>
          </a>
          <a href="#contact" className="nav-menu-item light contact-item">
            <img src={tel} alt="tel" className=""/>
            <span>CONTATO</span>
          </a>
          <a href="#location" className="nav-menu-item light">
            <img src={location} alt="tel" className=" menu-location-icon"/>
            <span>LOCALIZAÇÃO</span>
          </a>
        </nav>
        <div className="menu-contact">
          <span className="regular">
            <img src={whats} alt="whats" className="menu-whats-icon"/>
            WhatsApp
          </span>
          <span className="regular">
            <img src={tel} alt="tel" className="menu-items-icon"/>
            11 5081-6514
          </span>
        </div>
        <div className="menu-redes-sociais">
          <span className="regular">SIGA NOSSAS REDES SOCIAIS</span>
          <div>

            <a href="https://www.instagram.com/_u/davsom.sa/" target="_blank" rel="noreferrer">
              <img className="menu-icon-show" src={insta} alt="insta"/>
            </a>
            <a href='fb://page/davsom.sa' target='_blank' name='facebook' id='facebook' rel="noreferrer">
              <img className="menu-icon-show" src={face} alt="facebook"/>
            </a>
          </div>
        </div>
      </div>
    </div>
    )
  }
}

Menu.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default Menu;
