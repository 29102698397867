import '../css/ServiceList.css';
import React from 'react';
import whatsAppIcon from '../imgs/icon-whatsApp.png';

const serviceList = [
  {
    title: 'TRAVA MULTILOCK',
    description: `A Trava Multilock é feita com aço de alta resistência. Desencoraja o roubo ou furto, pois impede a movimentação do${'\u00A0'}automóvel.`,
  },
  {
    title: 'CENTRAL MULTIMÍDIA',
    description: `Os aparelhos multimídias acompanham: Espelhamento de celular, DVD, Câmera-ré, TV Digital, GPS, tudo em um único${'\u00A0'}aparelho!`,
  },
  {
    title: 'PELÍCULA AUTOMOTIVA',
    description: `As películas controlam a entrada de raios UV, trazem equilibrio térmico, ajudam na conservação de painéis e aumentam a segurança das${'\u00A0'}viagens.`,
  }
]

class ServiceList extends React.Component {
  constructor() {
    super();

    this.state = {
      serviceChange: false,
    }

    this.effectService = this.effectService.bind(this);
    this.effectService2 = this.effectService2.bind(this);
  }

  componentDidMount() {
    this.effectService();
  }

  effectService() {
    this.setState({serviceChange: true});
    setTimeout(this.effectService2, 2000);
  }

  effectService2() {
    this.setState({serviceChange: false});
    setTimeout(this.effectService, 2000);
  }

  changeImage(serviceChange, index) {
    if (index === 0) return ((serviceChange) ? (`service-img item-service-list services${index + 1}-b`)
    : (`service-img item-service-list services${index + 1}`))
    return `service-img item-service-list services${index + 1}`;
  }

  render() {
    const { serviceChange } = this.state;
  return serviceList.map((service, index) => (
    <div key={index} className='service-item'>
      <div className={ this.changeImage(serviceChange, index)}></div>
      <div className='service-text'>
        <span className='service-title black'>{service.title}</span>
        <span className='service-description light'>{service.description}</span>
        <a
          className='service-button light'
          href='https://api.whatsapp.com/send?phone=5511985841208&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Gostaria%20de%20um%20or%C3%A7amento'
          target='_blank'
          rel="noreferrer"
        >
          Tire suas dúvidas
          <img className='service-button-icon' src={whatsAppIcon} alt='whatsapp' />
        </a>
      </div>
    </div>
  ))
  }
}

export default ServiceList;
