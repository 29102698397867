import '../css/Header.css';
import React from 'react';
import whatsAppIcon from '../imgs/icon-whatsApp.png';
import PropTypes from 'prop-types';

class Header extends React.Component {
  render() {
    const { handleClick, hide } = this.props;
    return (
      <header id='header'>
        <div
          className={hide ? "menu-icon-body menu-icon-header" :"menu-icon-body menu-icon-header hide"}
          onClick={handleClick}
        >
          <div onClick={handleClick} className="menu-icon"></div>
          <div onClick={handleClick} className="menu-icon"></div>
          <div onClick={handleClick} className="menu-icon"></div>
        </div>
        <div className='logo-header'></div>
        <nav className='nav-menu'>
          <a href='#header' className='nav-item'>HOME</a>
          <a href='#services' className='nav-item'>SERVIÇOS</a>
          <a href='#contact' className='nav-item'>CONTATO</a>
          <a href='#location' className='nav-item'>LOCALIZAÇÃO</a>
        </nav>
        <a
          href='https://api.whatsapp.com/send?phone=5511985841208&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Gostaria%20de%20um%20or%C3%A7amento'
          target='_blank'
          className='whats-header regular'
          rel="noreferrer"
        >
          <span>WHATSAPP</span>
          <img  className='whats-icon' src={whatsAppIcon} alt='' />
        </a>
      </header>
    )
  }
}

Header.propTypes = {
  handleClick: PropTypes.func.isRequired,
}

export default Header;
