import '../css/More-Services.css';
import React from 'react';

const otherServiceList = [
  {
    title: 'CONSERTOS',
    description: [`Travas${'\u00A0'}e${'\u00A0'}vidros`, `Modulo${'\u00A0'}de${'\u00A0'}vidro`, `Central${'\u00A0'}Multimídia`],
  },
  {
    title: 'AUDIO E VÍDEO',
    description: [`Desbloqueio${'\u00A0'}de${'\u00A0'}multimídia`, `Central${'\u00A0'}Multimídia`, `Multimídia${'\u00A0'}encosto de${'\u00A0'}cabeça`],
  },
  {
    title: 'CUSTOMIZAÇÃO',
    description: [`Descanso${'\u00A0'}de${'\u00A0'}braço`, `Sensor${'\u00A0'}de${'\u00A0'}ré`, `Banco${'\u00A0'}de${'\u00A0'}couro`, `Kit${'\u00A0'}ultraled`],
  },
];

class MoreServices extends React.Component {
  render() {
  return otherServiceList.map((service, index) => (
    <div key={index * 10} className='other-service-item'>
      <div className={`other-service-img img${index}`}></div>
      <span className='service-title black'>{service.title}</span>
      <span className='service-description light'>{service.description.map((desc, index) => <li key={index * 20}>{desc}</li>)}</span>
    </div>
  ))
  }
}

export default MoreServices;
