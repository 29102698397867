import React from 'react';
import '../css/WhatsFix.css';
import whatsAppIcon from '../imgs/icon-whatsApp.png';

class WhatsFix extends React.Component {
  render() {
    const { effectWhats } = this.props;
    return (
      <a
        href='https://api.whatsapp.com/send?phone=5511985841208&text=Ol%C3%A1%2C%20tudo%20bem%3F%20Gostaria%20de%20um%20or%C3%A7amento'
        target='_blank'
        rel="noreferrer"
      >
        {/* <span className={'effect-whats effect-whats-hide'}>Mande um WHATSAPP!</span> */}
        <div>
            <div className={effectWhats ? 'whats-fix' : 'whats-fix whats-jump'}>
            <img  className='whats-fix-icon logo-header' src={whatsAppIcon} alt='' />
            </div>
        </div>
      </a>
    )
  }
}

export default WhatsFix;
