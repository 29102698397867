import '../css/Banner.css';
import React from 'react';
import Arrow from '../imgs/direction-down.png';
import PropTypes from 'prop-types';

class Banner extends React.Component {
  render() {
    const { seta } = this.props;
    return (
      <div>
        <section id='banner'>
            <div className='banner'/>
            <span className='banner-text medium'>
              Que tal ficar conectado com tudo o que você gosta enquanto dirige? Seu carro mais moderno e&nbsp;interativo!
            </span>
        </section>
        <section id='frase'>
          <img className={seta ? 'seta-baixo' : 'seta-baixo seta-baixada'} src={Arrow} alt='seta-baixo' />
          <span className='frase bold text-up'>
            Nosso diferencial é o cuidado com o seu carro,
          </span>
          <span className='frase bold text-down'>
            entendemos o que ele significa para você!
          </span>
        </section>
      </div>
    )
  }
}

Banner.propTypes = {
  seta: PropTypes.bool,
}

Banner.defaultProps = {
  seta: true,
}

export default Banner;