import '../css/Location.css';
import React from 'react';
import Arrow from '../imgs/direction-down.png';
import PropTypes from 'prop-types';

class Location extends React.Component {
  render() {
    const { seta } = this.props;
    return (
      <section id='location'>
        <div>
          <img className={seta ? 'seta-baixo' : 'seta-baixo seta-baixada'} src={Arrow} alt='seta-baixo' />
          <span className='frase bold text-up'>Nosso endereço:</span>
          <span className='frase bold text-down'>Av. Lins de Vasconcelos, 2560 - Vila Mariana, SP</span>
        </div>
        <iframe id='google-maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3656.641469632408!2d-46.627296984516306!3d-23.581316868215797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce597db9faba63%3A0x2ae337396a97cb2e!2sAv.%20Lins%20de%20Vasconcelos%2C%202560%20-%20Cambuci%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001537-001!5e0!3m2!1spt-BR!2sbr!4v1630224135385!5m2!1spt-BR!2sbr" loading="lazy" title='google-location' className='google-location'></iframe>
        <img className={seta ? 'seta-baixo end-point' : 'seta-baixo seta-baixada end-point'} src={Arrow} alt='seta-baixo' />
      </section>
    )
  }
}

Location.propTypes = {
  seta: PropTypes.bool,
}

Location.defaultProps = {
  seta: true,
}

export default Location;
