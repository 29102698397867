import '../css/Footer.css';
import React from 'react';
import facebook from '../imgs/facebook-icon.png';
import instagram from '../imgs/instagram-icon.png';

class Footer extends React.Component {
  render() {
    return (
      <section id='footer'>
        <div className='top-footer'>
          <div className='logo-footer'/>
          <div className='info-footer'>
            <div>
              <span className='bold'>Horário de atendimento</span>
              <span>Seg a sex: 8:30 às 17:30</span>
              <span>Sab.: 8:30 às 13:30</span>
            </div>
            <div>
              <span className='bold'>Fale conosco</span>
              <a href="tel:011985841208">
                11 98584-1208
              </a>
              <a href="tel:01150816514">
                11 5081-6514
              </a>
            </div>
          </div>
        </div>
        <div className='redes-sociais'>
          <span className='bold'>SIGA NOSSAS REDES SOCIAIS</span>
          <nav>
            <a className="desktop" href='https://www.instagram.com/_u/davsom.sa/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' /></a>
            <a className="desktop" href='https://www.facebook.com/davsom.sa/' target='_blank' rel="noreferrer"><img src={facebook} alt='facebook' /></a>
            <a className="mobile" href='https://www.instagram.com/_u/davsom.sa/' target='_blank' rel="noreferrer"><img src={instagram} alt='instagram' /></a>
            <a className="mobile" href='fb://page/davsom.sa' target='_blank' rel="noreferrer"><img src={facebook} alt='facebook' /></a>
          </nav>
        </div>
      </section>
    )
  }
}

export default Footer;