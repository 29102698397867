import './css/App.css';
import Header from './js/Header';
import Banner from './js/Banner';
import Services from './js/Services';
import Location from './js/Location';
import Contact from './js/Contact';
import Footer from './js/Footer';
import Menu from './js/Menu';
import React from 'react';
import WhatsFix from './js/WhatsFix';

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      hide: true,
    }

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick({ target }) {
    const { hide } = this.state;
    this.setState({hide: true});
    if (hide) this.setState({hide: false});
  }

  render() {
    const { hide, seta, effectWhats } = this.state;
  return (
    <>
      <Menu handleClick={ this.handleClick } hide={hide}/>
      <main className={hide ? 'show' : 'blur'} onClick={ hide ? () => {} : this.handleClick } >
        <div className='top'>
          <Header handleClick={ this.handleClick } hide={hide}/>
          <Banner seta={seta} />
        </div>
        <Services seta={seta}/>
        <Location seta={seta}/>
        <Contact />
        <Footer />
      </main>
      {hide && <WhatsFix effectWhats={effectWhats}/>}
    </>
  );
  }
}

export default App;
