import '../css/Services.css';
import React from 'react';
import ServiceList from './ServiceList';
import MoreServices from './More-Services';
import Arrow from '../imgs/direction-down.png';
import PropTypes from 'prop-types';

class Services extends React.Component {
  render() {
    const { seta } = this.props;
    return (
      <section id='services'>
        <div className='services-main'>
        <ServiceList />
        </div>
        <div className='more-services-div'>
        <img className={seta ? 'seta-baixo' : 'seta-baixo seta-baixada'} src={Arrow} alt='seta-baixo' />
          <div className='more-services-title'>
            <span className='medium'>MAIS&nbsp;SERVIÇOS</span>
          </div>
        </div>
        <div className='services-other'>
        <MoreServices />
        </div>
      </section>
    )
  }
}

Services.propTypes = {
  seta: PropTypes.bool,
}

Services.defaultProps = {
  seta: true,
}

export default Services;