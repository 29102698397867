import '../css/Contact.css';
import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

const SERVICE_ID = 'service_zcsr1t4';
const TEMPLATE_ID = 'template_9kz8n8g';
const USER_ID = '3LjLgNQm9bpHhrbag';

function Contact() {
  const form = useRef();
  const [nome, setNome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [response, setResponse] = useState('');
  const [send, setSend] = useState(false);

  const onSubmitClick = async (e) => {
    e.preventDefault();
    try {
      if(send) return setSend(false);

      setResponse('Enviando...');
      setSend(true);

      await emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID);
      setResponse('Enviado com sucesso');
    } catch (err) {
      setResponse('Erro ao enviar');
      setSend(true);
    }
  }

  return (
    <section id='contact'>
      <div className='contact-car-img'></div>
    <form ref={ form } onSubmit={ onSubmitClick }>
    {send 
    ? (<>
      <span className="frase white medium">{response}</span>
      <button type="submit" className='contact-submit bold'>voltar</button>
      </>)
    : (
    <>
    <span className='contact-title bold text-up'>
        TIRE SUAS DÚVIDAS
        <br />
        SEM COMPROMISSO
      </span>
      <input
        className='contact-name'
        name="from_name"
        placeholder='Digite seu nome'
        value={nome}
        onChange={ (e) => setNome(e.target.value) }
      />
      <input
        className='contact-tel'
        name="telefone"
        placeholder='Digite seu telefone'
        value={telefone}
        onChange={ (e) => setTelefone(e.target.value) }
      />
      <button type="submit" className='contact-submit bold'>enviar</button>
      </>
      )}
    </form>
    </section>
  )
}

export default Contact;